<template>
	<div class="tcontainer">
		<Toast />
		<div style="margin: 10px;">
			<Calendar @hide="loadLazyData" placeholder="Select Range" v-model="dateRange" selectionMode="range" dateFormat='dd/mm/yy' />
		</div>

		<div class="clearfix ttitle">
			<div class="pull-right" style="margin: 5px;">
				Total Records: {{ totalRecords }}
			</div>
			<div class="title">Manage PFT Reports</div>
		</div>
		<DataTable stripedRows :value="tableRecords" :lazy="true" :resizableColumns="true" columnResizeMode="fit"
			:paginator="true" :rows="100" v-model:filters="filters" ref="dt" :totalRecords="totalRecords"
			:rowsPerPageOptions="[100, 200, 500, 1000]" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
			@filter="onFilter($event)" filterDisplay="row" responsiveLayout="scroll">


			<Column field="id" header="Id" filterField="id" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Id" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata" :style="{ 'font-weight': data.accepted ? 'bold' : 'normal' }">
						<span v-if="data.urgent == 'y'"
							style="border: 1px solid gray; border-radius: 6px; padding: 4px;background: #f00; font-weight: bold; color: #fff;">
							{{ data['id']}}
						</span>
						<span v-else
							style="border: 1px solid gray; border-radius: 6px; padding: 4px; font-weight: bold; ">{{ data['id']}}</span>
						<span v-if="data.urgent == 'y'" class="text-danger ms-1" style="font-weight: bold">Urgent</span>
					</div>
				</template>
			</Column>

			<Column field="test_type" header="Test Type" filterField="test_type" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Test Type" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['test_type']}}
					</div>
				</template>
			</Column>

			<Column field="center_name" header="Center Name" filterField="center_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Center Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['center_name']}} ({{ data['center_id'] }})
					</div>
				</template>
			</Column>
			<Column field="patient_name" header="Patient Name" filterField="patient_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Patient Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['patient_name']}} ({{ data['patient_age']}})
					</div>
				</template>
			</Column>
			<Column field="assign_doc_name" header="Assign Dr." filterField="assign_doc_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Assign Dr." />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata" :style="{ 'font-weight': data.accepted ? 'bold' : 'normal' }">
						{{ data['assign_doc_name']}}
					</div>
				</template>
			</Column>
			<Column field="status" header="Status" filterField="status" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Status" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata" :style="{ 'font-weight': data.accepted ? 'bold' : 'normal' }">
						<sup v-if="data.accepted && data['report'] && data['status'] == 'pending'"
							class="text-success pull-right">draft</sup>
						<span v-if="data['status'] == 'pending'" class="form-control p-0 text-center"
							style="background: #fd6d7b; color: #fff; padding: 5px; border-radius: 5px;">{{ data['status']}}</span>
						<span v-else-if="data['status'] == 'review'" class="form-control p-0 text-center"
							style="background: #fd6d7b; color: #fff; padding: 5px; border-radius: 5px;">{{ data['status']}}</span>
						<span v-else-if="data['status'] == 'reported'" class="form-control p-0 text-center bg-warning"
							style=" color: #fff; padding: 5px; border-radius: 5px;">{{ data['status']}}</span>
						<span v-else class="form-control p-0 text-center bg-success"
							style="color: #fff; padding: 5px; border-radius: 5px;">{{ data['status']}}</span>
					</div>
				</template>
			</Column>
			<Column field="created_at" header="Created At" filterField="created_at" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Created At" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						<div style="width: 65px; margin: auto;">
							{{ format(data['created_at'], "DD/MM/YY h:mmA") }}
						</div>
					</div>
				</template>
			</Column>
			<Column field="reported_at" header="Reported At" filterField="reported_at" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Reported At" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						<div class="tdata" v-if="data['reported_at']" style="width: 65px; margin: auto;">
							{{ format(data['reported_at'], "DD/MM/YY h:mmA") }}
						</div>
					</div>
				</template>
			</Column>
			<Column
				header="Options &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; ">
				<template #body="{ data }">
					<div class="tdata options">
						<button title="Edit" class="btn btn-sm btn-outline-warning" @click="showAddEdit(data)"><i
								class="fa fa-edit"></i></button>
						<button v-if="isSuper" class="btn btn-sm btn-outline-danger"
							@click="editId = data['id']; deleteModal.show();"><i class="fa fa-trash"></i></button>
						<button hidden v-if="data.status == 'reported' || data.status == 'printed'"
							class="btn btn-sm btn-outline-info" @click="makeReview(data);"><i
								class="fa fa-pencil"></i></button>
						<button v-if="data.status == 'reported'" title="Print" type="button"
							class="btn btn-sm btn-outline-success" @click="printReport(data)">
							<i class="fa fa-print"></i>
						</button>
						<button v-if="data.images?.length" @click="showImageViewer(data)"
							class="btn btn-sm btn-outline-secondary"><i class="fa fa-image"></i></button>
					</div>
				</template>
			</Column>
		</DataTable>

		<div class="modal fade" ref="deleteModal" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-danger text-white">
						<h5 class="modal-title">Delete Confirmation</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body bg-white text-body text-center font-weight-bold font-1xl">
						<div class="text-danger" style="font-weight: bold; text-align: left; margin-bottom: 8px;">Record ID:
							{{ editId }}</div>
						You are about to delete this record, Are you sure?
						<br>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-danger" @click="deleteRecord">Delete</button>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" ref="imageViewerModal" tabindex="-1">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-header bg-info text-white">
						<h5 class="modal-title">Images</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div>
						<div style="width: 200px; float: left;">
							<div style="margin-bottom: 10px;" v-for="image in allImages" :key="image">
								<img v-if="image.isPdf" style="max-width: 100%; cursor: pointer;" @click="showImage = image"
									src="../../assets/pdf.png" alt="">
								<img v-else @click="showImage = image" style="max-width: 100%; cursor: pointer;"
									:src="apiOrigin + '/images/pulmonology/' + image.file" alt="">
							</div>
						</div>
						<div style="margin-left: 210px;">
							<embed v-if="showImage?.isPdf" :src="apiOrigin + '/images/pulmonology/' + showImage.file"
								style="width: 100%; height: 675px;" type="application/pdf">
							<img style="display: block; margin: auto; max-width: 100%"
								:src="apiOrigin + '/images/pulmonology/' + showImage.file" alt="">
						</div>

					</div>
				</div>
			</div>
		</div>

		<form action="" @submit.prevent="putRecord">
			<div class="modal fade" id="exampleModal" ref="addModal" tabindex="-1" aria-labelledby="exampleModalLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-xl">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel">
								<div v-if="editId == 0">Add Patient</div>
								<div v-else>Edit Patient</div>
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="container">
								<div class="row">
									<div class="col-sm-6">
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.patient_name?.hasError && fcontrol.patient_name.touched }"
												v-model="fdata.patient_name" @blur="fcontrol.patient_name.touched = true"
												placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.patient_name?.hasError">
												<span v-if="fcontrol.patient_name.errors.required">You must enter patient
													name</span>
												<span v-if="fcontrol.patient_name.errors.max">Patient Name can contain maximum
													{{ fcontrol.patient_name.errors.max.maxLength }} characters</span>
											</div>
											<label>Patient Name</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.patient_age?.hasError && fcontrol.patient_age.touched }"
												v-model="fdata.patient_age" @blur="fcontrol.patient_age.touched = true"
												placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.patient_age?.hasError">
												<span v-if="fcontrol.patient_age.errors.required">You must enter patient age</span>
												<span v-if="fcontrol.patient_age.errors.max">Patient Age can contain maximum
													{{ fcontrol.patient_age.errors.max.maxLength }} characters</span>
											</div>
											<label>Patient Age</label>
										</div>
										<div class="form-group ui-float-label">
											<select class="form-control"
												:class="{ 'is-invalid': fcontrol.patient_gender?.hasError && fcontrol.patient_gender.touched }"
												v-model="fdata.patient_gender" @blur="fcontrol.patient_gender.touched = true">
												<option value="" disabled selected>Select Patient Gender</option>
												<option value="male">Male</option>
												<option value="female">Female</option>
											</select>
											<div class="invalid-feedback" v-if="fcontrol.patient_gender?.hasError">
												<span v-if="fcontrol.patient_gender.errors.required">You must enter patient
													gender</span>
												<span v-if="fcontrol.patient_gender.errors.in">Invalid patient gender</span>
											</div>
											<label>Patient Gender</label>
										</div>
										<div class="form-group ui-float-label">
											<div class="p-fluid">
												<AutoComplete placeholder="Referral Doctor" v-model="fdata.ref_doctor_name"
													:suggestions="refDoctors" @complete="searchRefDoctor($event)" field="name" />
											</div>
											<div class="invalid-feedback" :style="{ display: refDoctorInvalid ? 'block' : 'none' }">
												<span>You must enter Referral doctor</span>
											</div>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.bp_systole?.hasError && fcontrol.bp_systole.touched }"
												v-model="fdata.bp_systole" @blur="fcontrol.bp_systole.touched = true"
												placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.bp_systole?.hasError">
												<span v-if="fcontrol.bp_systole.errors.required">You must enter bp systole</span>
												<span v-if="fcontrol.bp_systole.errors.max">Bp Systole can contain maximum
													{{ fcontrol.bp_systole.errors.max.maxLength }} characters</span>
											</div>
											<label>Bp Systole</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.bp_diastole?.hasError && fcontrol.bp_diastole.touched }"
												v-model="fdata.bp_diastole" @blur="fcontrol.bp_diastole.touched = true"
												placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.bp_diastole?.hasError">
												<span v-if="fcontrol.bp_diastole.errors.required">You must enter bp diastole</span>
												<span v-if="fcontrol.bp_diastole.errors.max">Bp Diastole can contain maximum
													{{ fcontrol.bp_diastole.errors.max.maxLength }} characters</span>
											</div>
											<label>Bp Diastole</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.spo2?.hasError && fcontrol.spo2.touched }"
												v-model="fdata.spo2" @blur="fcontrol.spo2.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.spo2?.hasError">
												<span v-if="fcontrol.spo2.errors.required">You must enter spo2</span>
												<span v-if="fcontrol.spo2.errors.max">Spo2 can contain maximum
													{{ fcontrol.spo2.errors.max.maxLength }} characters</span>
											</div>
											<label>Spo2</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.height?.hasError && fcontrol.height.touched }"
												v-model="fdata.height" @blur="fcontrol.height.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.height?.hasError">
												<span v-if="fcontrol.height.errors.required">You must enter height</span>
												<span v-if="fcontrol.height.errors.max">Height can contain maximum
													{{ fcontrol.height.errors.max.maxLength }} characters</span>
											</div>
											<label>Height</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.weight?.hasError && fcontrol.weight.touched }"
												v-model="fdata.weight" @blur="fcontrol.weight.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.weight?.hasError">
												<span v-if="fcontrol.weight.errors.required">You must enter weight</span>
												<span v-if="fcontrol.weight.errors.max">Weight can contain maximum
													{{ fcontrol.weight.errors.max.maxLength }} characters</span>
											</div>
											<label>Weight</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.symptoms?.hasError && fcontrol.symptoms.touched }"
												v-model="fdata.symptoms" @blur="fcontrol.symptoms.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.symptoms?.hasError">
												<span v-if="fcontrol.symptoms.errors.required">You must enter symptoms</span>
											</div>
											<label>Symptoms</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="date" class="form-control"
												:class="{ 'is-invalid': fcontrol.test_date?.hasError && fcontrol.test_date.touched }"
												v-model="fdata.test_date" @blur="fcontrol.test_date.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.test_date?.hasError">
												<span v-if="fcontrol.test_date.errors.required">You must enter test date</span>
											</div>
											<label>Test Date</label>
										</div>
										<div class="form-group ui-float-label">
											<textarea rows="2" :disabled="editRow?.status == 'reported'" class="form-control"
												:class="{ 'is-invalid': fcontrol.history?.hasError && fcontrol.history.touched }"
												v-model="fdata.history" @blur="fcontrol.history.touched = true"
												placeholder="History"></textarea>
											<div class="invalid-feedback" v-if="fcontrol.history?.hasError">
												<span v-if="fcontrol.history.errors.required">You must enter history</span>
											</div>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.reg_no?.hasError && fcontrol.reg_no.touched }"
												v-model="fdata.reg_no" @blur="fcontrol.reg_no.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.reg_no?.hasError">
												<span v-if="fcontrol.reg_no.errors.required">You must enter reg no</span>
												<span v-if="fcontrol.reg_no.errors.max">Reg No can contain maximum
													{{ fcontrol.reg_no.errors.max.maxLength }} characters</span>
											</div>
											<label>Reg No/ Bill No</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.mobile_no?.hasError && fcontrol.mobile_no.touched }"
												v-model="fdata.mobile_no" @blur="fcontrol.mobile_no.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.mobile_no?.hasError">
												<span v-if="fcontrol.mobile_no.errors.required">You must enter mobile no</span>
												<span v-if="fcontrol.mobile_no.errors.max">Mobile No can contain maximum
													{{ fcontrol.mobile_no.errors.max.maxLength }} characters</span>
											</div>
											<label>Mobile No</label>
										</div>

										<div class="form-group ui-float-label">
											<select class="form-control"
												:disabled="(editRow && editRow?.status != 'pending') || editRow?.accepted_at"
												:class="{ 'is-invalid': fcontrol.assign_doc_id?.hasError && fcontrol.assign_doc_id.touched }"
												v-model="fdata.assign_doc_id" @blur="fcontrol.assign_doc_id.touched = true">
												<option value="0">Any Available Doctors</option>
												<option v-for="d in pulmonologists" :key="d.id" :value="d.id">{{ d.name }}</option>
											</select>
											<div class="invalid-feedback" v-if="fcontrol.assign_doc_id?.hasError">
												<span v-if="fcontrol.assign_doc_id.errors.required">You must enter assign to
													doctor</span>
											</div>
											<label>Assign To Doctor</label>
										</div>
										<div class="form-group ui-float-label">
											<select class="form-control"
												:class="{ 'is-invalid': fcontrol.status?.hasError && fcontrol.status.touched }"
												v-model="fdata.status" @blur="fcontrol.status.touched = true">
												<option value="pending">Pending</option>
												<option value="reported">Reported</option>
												<option value="printed">Printed</option>
												<option value="review">Review</option>
												<option value="substandard">Substandard</option>
											</select>
											<div class="invalid-feedback" v-if="fcontrol.status?.hasError">
												<span v-if="fcontrol.status.errors.required">You must select status</span>
											</div>
											<label>Status</label>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="form-group">
											<input type="file" id="image" :disabled="editRow?.status == 'reported'"
												accept="image/*,application/pdf" multiple style="display: none" class="form-control"
												@change="selectImages()" ref="images" />
											<label for="image" style="display: block">
												<div class="form-control">Select PFT Files</div>
											</label>
										</div>
										<div class="images">
											<div v-for="image in images" :key="image">
												<i v-if="!(editRow?.status == 'reported')" @click="removeImage(image)"
													class="fa fa-close text-danger"></i>
												<img v-if="!image.isPdf" :src="image.url" alt="">
												<embed v-else :src="image.url" style="width: 100%; height: 475px;"
													type="application/pdf">
											</div>
										</div>
									</div>
									<div class="col-sm-12" v-if="saving">
										<ProgressBar :value="uploadPercentage">
											Sending: {{ uploadPercentage }}%
										</ProgressBar>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							<button type="submit" class="btn btn-primary">Save</button>
						</div>
					</div>
				</div>
			</div>
		</form>

	</div>
</template>

<script>
import ProgressBar from 'primevue/progressbar';
import AutoComplete from 'primevue/autocomplete';
import Toast from 'primevue/toast';
import { Modal } from 'bootstrap';
import Calendar from 'primevue/calendar';

export default {
	components: {
		AutoComplete,
		Toast,
		ProgressBar,
		Calendar
	},
	data() {
		return {
			isSuper: false,
			loading: false,
			totalRecords: 0,
			tableRecords: null,
			dateRange: "",
			editId: 0,
			editRow: null,
			saving: false,
			addModal: null,
			deleteModal: null,
			imageViewerModal: null,
			allImages: [],
			showImage: "",
			lazyParams: {},
			refDoctors: null,
			tests: [],
			filteredTests: null,
			pulmonologists: [],
			images: [],
			/*
				 {
					  url: 
					  name:
					  file: 
				 }
			*/
			removedImages: [],
			refDoctorInvalid: false,
			testNameInvalid: false,
			filters: {
				'id': { value: '', matchMode: 'like' },
				'center_id': { value: '', matchMode: 'like' },
				'test_type': { value: '', matchMode: 'like' },
				'center_name': { value: '', matchMode: 'like' },
				'patient_name': { value: '', matchMode: 'like' },
				'patient_age': { value: '', matchMode: 'like' },
				'patient_gender': { value: '', matchMode: 'like' },
				'ref_doctor_name': { value: '', matchMode: 'like' },
				'test_id': { value: '', matchMode: 'like' },
				'test_name': { value: '', matchMode: 'like' },
				'assign_doc_name': { value: '', matchMode: 'like' },
				'test_date': { value: '', matchMode: 'like' },
				'history': { value: '', matchMode: 'like' },
				'reg_no': { value: '', matchMode: 'like' },
				'mobile_no': { value: '', matchMode: 'like' },
				'assign_doc_id': { value: '', matchMode: 'like' },
				'assign_doc_ids': { value: '', matchMode: 'like' },
				'assign_type': { value: '', matchMode: 'like' },
				'images': { value: '', matchMode: 'like' },
				'report': { value: '', matchMode: 'like' },
				'status': { value: '', matchMode: 'like' },
				'urgent': { value: '', matchMode: 'like' },
				'review_notes': { value: '', matchMode: 'like' },
				'review_done': { value: '', matchMode: 'like' },
				'notes': { value: '', matchMode: 'like' },
				'price': { value: '', matchMode: 'like' },
				'doctor_price': { value: '', matchMode: 'like' },
				'agent_id': { value: '', matchMode: 'like' },
				'agent_price': { value: '', matchMode: 'like' },
				'created_at': { value: '', matchMode: 'like' },
				'reported_at': { value: '', matchMode: 'like' },
				'accepted_at': { value: '', matchMode: 'like' },
			},
			fdata: {
				id: '',
				patient_name: '',
				patient_age: '',
				patient_gender: '',
				ref_doctor_name: '',
				bp_systole: '',
				bp_diastole: '',
				spo2: '',
				height: '',
				weight: '',
				symptoms: '',
				test_id: '',
				test_date: '',
				history: '',
				reg_no: '',
				mobile_no: '',
				assign_doc_id: '0',
				status: 'pending',
			},
			fvalidator: {
				patient_name: 'required|max:30',
				patient_age: 'required|max:15',
				patient_gender: 'required|in:male,female',
				ref_doctor_name: 'required',
				bp_systole: 'max:255',
				bp_diastole: 'max:255',
				spo2: 'max:255',
				height: 'max:255',
				weight: 'max:255',
				symptoms: 'required',
				// test_id: 'required',
				test_date: 'required',
				history: 'required',
				reg_no: 'max:40',
				mobile_no: 'max:15',
				assign_doc_id: 'required',
				status: 'required',
			}
		}
	},
	mounted() {
		this.addModal = new Modal(this.$refs.addModal, { backdrop: 'static', keyboard: false });
		this.deleteModal = new Modal(this.$refs.deleteModal);
		this.imageViewerModal = new Modal(this.$refs.imageViewerModal);

		this.lazyParams = {
			first: 0,
			rows: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
			filters: this.filters,
		};
		this.loadLazyData();

	},
	methods: {
		printReport(row){
			let data =  btoa(row.center_id+','+row.id).replaceAll(/=/ig, "");

			this.$router.push({
				path: "/print-pft-report",
				query: {
					data
				}
			})
		},
		enter() {
			let user = JSON.parse(window.localStorage.admin_access_token);
			this.isSuper = user.is_super == 'y';
		},
		showImageViewer(data) {
			if (!data.images) return;
			this.allImages = data.images.split(",");
			for (let i in this.allImages) {
				this.allImages[i] = {
					file: this.allImages[i],
					isPdf: this.allImages[i].indexOf(".pdf") != -1
				}
			}

			this.showImage = this.allImages[0];

			this.imageViewerModal.show();
		},
		deleteRecord() {
			this.delete("admin/delete-pft_report/" + this.editId).then(res => {
				this.saving = false;
				if (res.success) {
					this.deleteModal.hide();
					this.loadLazyData();
				}
			});
		},
		async makeReview(data) {
			await this.post('admin/pft-report-make-review', {
				id: data.id
			});
			this.loadLazyData();
		},
		selectImages() {
			let files = this.$refs['images'].files;
			for (let i = 0; i < files.length; i++) {
				let img = files[i];

				var reader = new FileReader();
				reader.onload = e => {
					this.images.push({
						url: e.target.result,
						file: img,
						isPdf: img.type == "application/pdf",
					});
				}
				reader.readAsDataURL(img);
			}
			this.$refs['images'].value = '';
		},
		removeImage(image) {
			this.images = this.images.filter(fimg => fimg != image);
			if (image.name) this.removedImages.push(image.name);
		},
		putRecord() {
			if (this.saving) return;
			let hasError = false;
			if (this.fHasError()) hasError = true;

			if (!this.fdata.ref_doctor_name) {
				hasError = true;
				this.refDoctorInvalid = true;
			} else {
				this.refDoctorInvalid = false;
			}
			if (hasError) return;
			if (!this.editId && !this.images.length) return;

			let data = JSON.parse(JSON.stringify(this.fdata));
			if (typeof this.fdata.ref_doctor_name == 'object') data.ref_doctor_name = this.fdata.ref_doctor_name.name;
			this.saving = true;


			let totalNewImages = 0;
			for (let image of this.images) {
				if (image.file) {
					data['image' + totalNewImages] = image.file;
					totalNewImages++;
				}
			}
			data.totalNewImages = totalNewImages;

			if (this.editId) {
				data.id = this.editId;
				data.center_id = this.editRow.center_id;
				data.removedImages = this.removedImages.join(",");
			}
			this.upload("admin/put-pft-report", data, (err, res) => {
				this.saving = false;
				if (res.success) {
					this.addModal.hide();
					this.loadLazyData();
					this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Report submitted successfully.', life: 3000 });
				} else if (res.lowBalance) {
					this.$toast.add({ severity: 'warn', summary: 'Low Balance', detail: 'You have low balance. Please recharge to continue', life: 3000 });
				} else {
					this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Sorry something went worng, Please try again later.', life: 3000 });
				}
			}, p => {
				this.uploadPercentage = Math.round(p * 100);
			})
		},
		searchRefDoctor(event) {
			if (!event.query.trim().length) {
				this.refDoctors = [];
			} else {
				this.post('admin/filter-ref-doctors', {
					prefix: event.query.toLowerCase(),
					center_id: this.editRow.center_id
				}).then(res => {
					this.refDoctors = res;
				})
			}
		},
		async showAddEdit(row) {
			this.freset();
			this.refDoctorInvalid = false;
			this.testNameInvalid = false;

			this.images = [];
			this.removedImages = [];


			this.editId = row ? row.id : 0;
			this.editRow = row;

			this.pulmonologists = await this.get("admin/load-pulmonologists/" + row.center_id);

			let images = row.images ? row.images.split(",") : [];
			for (let i of images) {
				this.images.push({
					url: this.apiOrigin + "/" + 'images/pulmonology/' + i,
					name: i,
					isPdf: i.indexOf(".pdf") != -1,
				})
			}

			row.assign_doc_id = String(row.assign_doc_id);
			row.test_date = row.test_date.split("T")[0]
			this.fpopulate(row);
			this.fdata.test_id = this.tests.find(r => r.id == row.test_id);

			this.addModal.show();
		},
		loadLazyData() {
			this.loading = true;
			let params = JSON.parse(JSON.stringify(this.lazyParams));
			for (let f in params.filters) {
				if (!params.filters[f].value.length) delete params.filters[f];
			}

			if (!params.sortField) {
				params.sortField = "id";
				params.sortOrder = -1;
			}

			params.joins = [
				{
					"fTable": "pulmonologists",
					"fKey": "assign_doc_id",
					"fField": [["name", "assign_doc_name"]]
				},
				{
					"fTable": "centers",
					"fKey": "center_id",
					"fField": [["name", "center_name"]]
				},
			]
			if (this.dateRange[0] && this.dateRange[1]) {
				params.filters['pft_reports.created_at'] = { value: [this.format(this.dateRange[0], "YYYY-MM-DD")+' 00:00:00', this.format(this.dateRange[1], "YYYY-MM-DD")+" 23:59:59"], matchMode: "between" };
			}

			this.post("admin/load-pft_reports", params).then(res => {
				this.loading = false;
				if (res.success) {
					this.totalRecords = res.totalRecords;
					this.tableRecords = res.rows;
				}
			});
		},
		onPage(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onSort(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onFilter() {
			this.loading = true;
			this.lazyParams.filters = this.filters;
			this.loadLazyData();
		}
	}
}
</script>
<style scoped>
.images>div {
	position: relative;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	margin-bottom: 5px;
}

.images img {
	max-width: 100%;
	margin: auto;
	display: block;
}

.images>div .fa-close {
	font-size: 35px;
	cursor: pointer;
	position: absolute;
	right: 10px;
	top: 10px;
}
</style>